<template>
    <div>
        <div class="parent">
            <div class="classic-3"></div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="css" scoped>
.parent{
    display: flex;
    justify-content: center;
}
    .classic-3 {
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  overflow: hidden;
}

.classic-3::before {
  content:"Loading...";
  color:#0000;
  text-shadow: 0 0 0 #000,10ch 0 0 #fff,20ch 0 0 #000;
  background:linear-gradient(90deg,#0000 calc(100%/3),#000 0 calc(2*100%/3),#0000 0) left/300% 100%;
  animation:c3 2s infinite;
}

@keyframes c3{
  50% {background-position: center;text-shadow: -10ch 0 0 #000,    0 0 0 #fff,10ch 0 0 #000}
  100%{background-position: right ;text-shadow: -20ch 0 0 #000,-10ch 0 0 #fff,   0 0 0 #000}
}

</style>